/** @jsx jsx */
import React from 'react'; //eslint-disable-line no-unused-vars
import { Box, Container, Grid, Flex, Heading, jsx } from 'theme-ui';
import { graphql, navigate } from 'gatsby';

import ReactPaginate from 'react-paginate';
// import Pagination from 'react-js-pagination';

import parse from 'html-react-parser';

import Footer from '../components/Footer';
import Header from '../components/Header';
import BlogCard from '../components/BlogCard';
import SEO from '../components/SEO';

const parseImage = html => {
  const data = parse(html);
  let image = data.find(x => x.type === 'img');
  if (image) {
    return image.props.src;
  }
  return false;
};

// context or w/e may (not matter what it's called). data is w/e the query is.
const BlogListTemplate = ({ pageContext, data }) => {
  let dosisNewsPostsCards = [];
  let otherNewsPostsCards = [];

  const { numDosisNewsPages,
          currentDosisNewsPage,
          currentOtherNewsPage,
          numOtherNewsPages, } = pageContext;

  const allFeedScienceDaily = data.allFeedScienceDailyNews.edges;
  const feedDocWireNewsBlogs = data.allFeedDocWireNews.edges;
  const feedDosisNews = data.allFeedDosisNews.edges;
  const dosisBlogs = data.allMarkdownRemark.edges;

  const totalBlogs = [
    dosisBlogs,
    feedDosisNews,
    allFeedScienceDaily,
    feedDocWireNewsBlogs,
  ];

  for (let i = 0; i < totalBlogs.length; i++) {
    const blogList = totalBlogs[i];

    for (let j = 0; j < blogList.length; j++) {
      const blog = blogList[j];

      if (i === 0) {
        let { title, date, slug } = blog.node.frontmatter;
        let excerpt = blog.node.excerpt;
        let image = blog.node.frontmatter.thumbnail;

        dosisNewsPostsCards.push({
          blogImageFound:  'cover',
          category:        'Dosis Updates',
          date:            date,
          excerpt:         excerpt,
          image:           image,
          link:            slug,
          title:           title,
        });
      } else {
        let { isoDate, contentSnippet, link, title } = blog.node;

        const encoded =
          typeof blog.node.content !== 'undefined' && blog.node.content.encoded;

        let image = null;
        let imageFound = 'cover';

        if (encoded !== false) {
          // Parse content encoded for image; if none, fall back to feedMeta image
          // if it exists, if feedMeta image doesn't exist, just don't load an image

          imageFound = true;
          image = parseImage(encoded);
        } else {
          if (i === 2) {
            imageFound = 'contain';
            image = data.allFeedScienceDailyNewsMeta.nodes[0].image.url;
          }
        }

        if (contentSnippet.split(' ').length > 15) {
          contentSnippet = contentSnippet.split(' ');

          const remainingSnippet = contentSnippet.length - 15;
          contentSnippet.splice(-remainingSnippet);

          contentSnippet = contentSnippet.join(' ');
        }

        if (contentSnippet.includes('[…]')) {
          contentSnippet = contentSnippet.replace('[…]', '...');
        } else {
          contentSnippet += ' ...';
        }

        (i === 1 ? dosisNewsPostsCards : otherNewsPostsCards).push({
          blogImageFound:  imageFound,
          category:        (i === 1 ? 'Dosis News' : 'From the News'),
          date:            isoDate,
          excerpt:         contentSnippet,
          image:           image,
          link:            link,
          title:           title,
        });
      }
    }
  }

  let dosisNewsPostsCardsSorted =
    dosisNewsPostsCards.sort((a, b) => new Date(b.date) - new Date(a.date));
  let otherNewsPostsCardsSorted =
    otherNewsPostsCards.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <>
      <SEO title='News &amp; Insights' />

      <Header
        heroImage={data.heroDots.publicURL}
        heroTitle='News &#38; Insights'
        heroVariant='medium'
      />

      <Box as='section'>
        <Container py={[null, null, null, 12]}>
          <Flex sx={{ justifyContent: 'center' }}>
            <Box sx={{ flex: '0 0 100%', textAlign: 'center' }}>
              <Heading as='h1' sx={{ textAlign: 'center' }}>
                Company News
              </Heading>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box as='main' py={[14, null, null, 12]}>
        <Container>
          <Grid
            sx={{
              gridTemplateColumns: [
                'repeat(1, 100%)',
                'repeat(1, 80%)',
                'repeat(2, 48%)',
                'repeat(3, 32%)',
              ],

              justifyContent: ['center', null, null, 'space-between'],
              rowGap: [10, null, null, 13],
            }}
          >
            {dosisNewsPostsCardsSorted.map((post, index) => (
              <BlogCard
                key={index}
                blogCategory={post.category}
                blogCategoryColor={
                  post.category === 'From the News' ? 'blue' : 'primary'
                }
                blogDate={post.date}
                blogExcerpt={post.excerpt}
                blogImageURL={post.image}
                blogLinkURL={post.link}
                imageFound={post.blogImageFound}
                blogTitle={post.title}
              />
            ))}
          </Grid>
        </Container>

        <Container>
          <div
            sx={{
              '.pagination': {
                alignItems: 'center',
                display: 'flex',
                fontSize: [1, null, null, 3],
                justifyContent: 'center',
                listStyleType: 'none',
                mb: 0,
                mt: 13,
                p: 0,
              },
              '.previous,.next': {},
              '.next': {},
              '.previous,': {},
              '.break-me': {},
              '.active a': {
                color: '#51BFA0',
                textDecoration: 'underline',
              },
              'li:not(:first-of-type)': {
                ml: [2, null, null, 5],
              },
              a: {
                color: 'black',
                outline: 'none',
                textDecoration: 'none',
              },
              'a:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <ReactPaginate
              previousLabel={'« Previous'}
              nextLabel={'Next »'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={numDosisNewsPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={4}
              forcePage={currentDosisNewsPage - 1}
              onPageChange={e => {
                let url = '/news/';
                if (e.selected > 0 || currentOtherNewsPage > 1) {
                  url += `company/${e.selected + 1}/industry/${currentOtherNewsPage}`
                };
                navigate(url);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              hrefBuilder={e => `/news/${e}`}
              sx={{ m: 4 }}
            />
          </div>
        </Container>
      </Box>


      <Box as='section'
        backgroundColor='lightGray'
        py={[14, null, null, null]}
        >
        <Container>
          <Flex sx={{ justifyContent: 'center' }}>
            <Box sx={{ flex: '0 0 100%', textAlign: 'center' }}>
              <Heading as='h1' sx={{ textAlign: 'center' }}>
                Industry News
              </Heading>
            </Box>
          </Flex>
        </Container>
      </Box>

      <Box as="section" py={[14, null, null, null]}>
        <Container>
          <Grid
            sx={{
              gridTemplateColumns: [
                'repeat(1, 100%)',
                'repeat(1, 80%)',
                'repeat(2, 48%)',
                'repeat(3, 32%)',
              ],

              justifyContent: ['center', null, null, 'space-between'],
              rowGap: [10, null, null, 13],
            }}
          >
            {otherNewsPostsCardsSorted.map((post, index) => (
              <BlogCard
                key={index}
                blogCategory={post.category}
                blogCategoryColor='blue'
                blogDate={post.date}
                blogExcerpt={post.excerpt}
                blogImageURL={post.image}
                blogLinkURL={post.link}
                imageFound={post.blogImageFound}
                blogTitle={post.title}
              />
            ))}
          </Grid>
        </Container>

        <Container>
          <div
            sx={{
              '.pagination': {
                alignItems: 'center',
                display: 'flex',
                fontSize: [1, null, null, 3],
                justifyContent: 'center',
                listStyleType: 'none',
                mb: 0,
                mt: 13,
                p: 0,
              },
              '.previous,.next': {},
              '.next': {},
              '.previous,': {},
              '.break-me': {},
              '.active a': {
                color: '#51BFA0',
                textDecoration: 'underline',
              },
              'li:not(:first-of-type)': {
                ml: [2, null, null, 5],
              },
              a: {
                color: 'black',
                outline: 'none',
                textDecoration: 'none',
              },
              'a:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <ReactPaginate
              previousLabel={'« Previous'}
              nextLabel={'Next »'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={numOtherNewsPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={4}
              forcePage={currentOtherNewsPage - 1}
              onPageChange={e => {
                let url = `/news/`;
                if (e.selected > 0 || currentDosisNewsPage > 1) {
                  url += `company/${currentDosisNewsPage}/industry/${e.selected + 1}`;
                }
                navigate(url);
              }}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              hrefBuilder={e => `/news/${e}`}
              sx={{ m: 4 }}
            />
          </div>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

// blogPostLimit: blogPostCount,
// scienceDailyLimit: scienceDailyCount,
// docWireNewsLimit: docWireNewsCount,
// skipBlogs: lastBlogPost,
// skipScienceDaily: lastScienceDaily,
// skipDocWireNews: lastDocWireNews,
// numPages: pages.length,
// currentPage: i + 1

export default BlogListTemplate;

export const blogListQuery = graphql`
  query blogListQuery(
    $blogPostLimit:      Int!
    $scienceDailyLimit:  Int!
    $docWireNewsLimit:   Int!
    $dosisNewsLimit:     Int!
    $skipBlogPosts:      Int!
    $skipScienceDaily:   Int!
    $skipDocWireNews:    Int!
    $skipDosisNews:      Int!
  ) {
    heroBackgroundDots: file(relativePath: { eq: "hero-background-dots.svg" }) {
      id
      publicURL
      extension
    }
    heroDots: file(relativePath: { eq: "hero-dots-2.svg" }) {
      id
      publicURL
      extension
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { slug: { ne: null } } }
      limit: $blogPostLimit
      skip: $skipBlogPosts
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            thumbnail
          }
        }
      }
    }
    allFeedScienceDailyNews(
      limit: $scienceDailyLimit
      skip: $skipScienceDaily
    ) {
      edges {
        node {
          contentSnippet
          id
          isoDate(formatString: "MM/DD/YYYY")
          link
          title
        }
      }
    }
    allFeedDocWireNews(limit: $docWireNewsLimit, skip: $skipDocWireNews) {
      edges {
        node {
          content {
            encoded
          }
          contentSnippet
          id
          isoDate(formatString: "MM/DD/YYYY")
          link
          title
        }
      }
    }
    allFeedDosisNews (
      limit: $dosisNewsLimit
      skip: $skipDosisNews
    ) {
      edges {
        node {
          contentSnippet
          id
          isoDate(formatString: "MM/DD/YYYY")
          link
          title
        }
      }
    }
    allFeedScienceDailyNewsMeta {
      nodes {
        image {
          url
        }
      }
    }
  }
`;
